export const API_URL = "https://api.mapa.group/api"
//export const API_URL = "https://mapa-api.test.liberyus.com/api"


export const AUTH_LOGIN_URL = `${API_URL}/Authentication/Login`
export const AUTH_REGISTER_URL = `${API_URL}/Authentication/Register`
export const FOOTER_GETALL_URL = `${API_URL}/Footer/GetAll`
export const FOOTER_GET_URL = `${API_URL}/Footer/Get`
export const FOOTER_UPDATE_URL = `${API_URL}/Footer/Update`
export const HEADER_GETALL_URL = `${API_URL}/Header/GetAll`
export const HEADER_GET_URL = `${API_URL}/Header/Get`
export const HEADER_CREATE_URL = `${API_URL}/Header/Create`
export const HEADER_UPDATE_URL = `${API_URL}/Header/Update`
export const HEADER_DELETE_URL = `${API_URL}/Header/Delete`
export const IMAGE_GETALL_URL = `${API_URL}/Image/GetAll`
export const IMAGE_GET_URL = `${API_URL}/Image/Get`
export const IMAGE_CREATE_URL = `${API_URL}/Image/Create`
export const IMAGE_DELETE_URL = `${API_URL}/Image/Delete`
export const IMAGEGROUP_GETALL_URL = `${API_URL}/ImageGroup/GetAll`
export const IMAGEGROUP_GET_URL = `${API_URL}/ImageGroup/Get`
export const IMAGEGROUP_CREATE_URL = `${API_URL}/ImageGroup/Create`
export const IMAGEGROUP_UPDATE_URL = `${API_URL}/ImageGroup/Update`
export const IMAGEGROUP_DELETE_URL = `${API_URL}/ImageGroup/Delete`
export const LANGUAGE_GETALL_URL = `${API_URL}/Language/GetAll`
export const LANGUAGE_GET_URL = `${API_URL}/Language/Get`
export const LANGUAGE_CREATE_URL = `${API_URL}/Language/Create`
export const LANGUAGE_UPDATE_URL = `${API_URL}/Language/Update`
export const LANGUAGE_DELETE_URL = `${API_URL}/Language/Delete`
export const PAGE_GETALL_URL = `${API_URL}/Page/GetAll`
export const PAGE_GET_URL = `${API_URL}/Page/Get`
export const PAGE_GETBYURL_URL = `${API_URL}/Page/GetByUrl`
export const PAGE_CREATE_URL = `${API_URL}/Page/Create`
export const PAGE_UPDATE_URL = `${API_URL}/Page/Update`
export const PAGE_DELETE_URL = `${API_URL}/Page/Delete`
export const SERVICE_GETALL_URL = `${API_URL}/Service/GetAll`
export const SERVICE_GET_URL = `${API_URL}/Service/Get`
export const SERVICE_CREATE_URL = `${API_URL}/Service/Create`
export const SERVICE_UPDATE_URL = `${API_URL}/Service/Update`
export const SERVICE_DELETE_URL = `${API_URL}/Service/Delete`
export const SETTING_GETALL_URL = `${API_URL}/Setting/GetAll`
export const SETTING_GET_URL = `${API_URL}/Setting/Get`
export const SETTING_UPDATE_URL = `${API_URL}/Setting/Update`
export const SHOWCASE_GETALL_URL = `${API_URL}/ShowCase/GetAll`
export const SHOWCASE_GETALLBYPAGE_URL = `${API_URL}/ShowCase/GetAllByPage`
export const SHOWCASE_GET_URL = `${API_URL}/ShowCase/Get`
export const SHOWCASE_CREATE_URL = `${API_URL}/ShowCase/Create`
export const SHOWCASE_UPDATE_URL = `${API_URL}/ShowCase/Update`
export const SHOWCASE_DELETE_URL = `${API_URL}/ShowCase/Delete`
export const SOCIALMEDIA_GETALL_URL = `${API_URL}/SocialMedia/GetAll`
export const SOCIALMEDIA_GET_URL = `${API_URL}/SocialMedia/Get`
export const SOCIALMEDIA_CREATE_URL = `${API_URL}/SocialMedia/Create`
export const SOCIALMEDIA_UPDATE_URL = `${API_URL}/SocialMedia/Update`
export const SOCIALMEDIA_DELETE_URL = `${API_URL}/SocialMedia/Delete`
export const USER_GETALL_URL = `${API_URL}/User/GetAll`
export const USER_GET_URL = `${API_URL}/User/Get`
export const USER_UPDATE_URL = `${API_URL}/User/Update`
export const USER_CHANGEPASSWORD_URL = `${API_URL}/User/ChangePassword`
export const USER_DELETE_URL = `${API_URL}/User/Delete`
export const FORM_GETALL_URL = `${API_URL}/Form/GetAll`
export const FORM_GET_URL = `${API_URL}/Form/Get`
export const FORM_CREATE_URL = `${API_URL}/Form/Create`
export const FORM_UPDATE_URL = `${API_URL}/Form/Update`
export const FORM_DELETE_URL = `${API_URL}/Form/Delete`
export const APPLICATION_URL = `${API_URL}/Application/Send`
export const FORMELEMENT_GETALL_URL = `${API_URL}/FormElement/GetAll`
export const FORMELEMENT_GETALLBYFORM_URL = `${API_URL}/FormElement/GetAllByForm`
export const FORMELEMENT_GET_URL = `${API_URL}/FormElement/Get`
export const FORMELEMENT_CREATE_URL = `${API_URL}/FormElement/Create`
export const FORMELEMENT_UPDATE_URL = `${API_URL}/FormElement/Update`
export const FORMELEMENT_DELETE_URL = `${API_URL}/FormElement/Delete`
export const FORMRESPONSE_GETALL_URL = `${API_URL}/FormResponse/GetAll`
export const FORMRESPONSE_GETALLBYFORM_URL = `${API_URL}/FormResponse/GetAllByForm`
export const FORMRESPONSE_GET_URL = `${API_URL}/FormResponse/Get`
export const FORMRESPONSE_CREATE_URL = `${API_URL}/FormResponse/Create`
export const FORMRESPONSE_DELETE_URL = `${API_URL}/FormResponse/Delete`
export const SEO_GETALL_URL = `${API_URL}/Seo/GetAll`
export const SEO_GET_URL = `${API_URL}/Seo/Get`
export const SEO_CREATE_URL = `${API_URL}/Seo/Create`
export const SEO_UPDATE_URL = `${API_URL}/Seo/Update`
export const SEO_DELETE_URL = `${API_URL}/Seo/Delete`
export const JOB_GETALL_URL = `${API_URL}/Job/GetAll`
export const JOB_GET_URL = `${API_URL}/Job/Get`
export const JOBPAGE_GETALL_URL = `${API_URL}/JobPage/GetAll`
export const JOBPAGE_GET_URL = `${API_URL}/JobPage/Get`
export const FILEGROUP_GETALL_URL = `${API_URL}/FileGroup/GetAll`
export const FILEGROUP_GET_URL = `${API_URL}/FileGroup/Get`
export const FILEGROUP_CREATE_URL = `${API_URL}/FileGroup/Create`
export const FILEGROUP_UPDATE_URL = `${API_URL}/FileGroup/Update`
export const FILEGROUP_DELETE_URL = `${API_URL}/FileGroup/Delete`
export const FILES_GETALL_URL = `${API_URL}/Files/GetAll`
export const FILES_GETALLBYGROUP_URL = `${API_URL}/Files/GetAllByGroup`
export const FILES_GET_URL = `${API_URL}/Files/Get`
export const FILES_CREATE_URL = `${API_URL}/Files/Create`
export const FILES_UPDATE_URL = `${API_URL}/Files/Update`
export const FILES_DELETE_URL = `${API_URL}/Files/Delete`
export const COUNTRY_GETALL_URL = `${API_URL}/Country/GetAll`
export const COUNTRY_GET_URL = `${API_URL}/Country/Get`
export const COUNTRY_CREATE_URL = `${API_URL}/Country/Create`
export const COUNTRY_UPDATE_URL = `${API_URL}/Country/Update`
export const COUNTRY_DELETE_URL = `${API_URL}/Country/Delete`
export const PROJECT_GETALL_URL = `${API_URL}/Project/GetAll`
export const PROJECT_GET_URL = `${API_URL}/Project/Get`
export const PROJECT_CREATE_URL = `${API_URL}/Project/Create`
export const PROJECT_UPDATE_URL = `${API_URL}/Project/Update`
export const PROJECT_DELETE_URL = `${API_URL}/Project/Delete`
export const PROJECTTYPE_GETALL_URL = `${API_URL}/ProjectType/GetAll`
export const PROJECTTYPE_GET_URL = `${API_URL}/ProjectType/Get`
export const PROJECTTYPE_CREATE_URL = `${API_URL}/ProjectType/Create`
export const PROJECTTYPE_UPDATE_URL = `${API_URL}/ProjectType/Update`
export const PROJECTTYPE_DELETE_URL = `${API_URL}/ProjectType/Delete`
