import React, { useEffect, useState } from 'react';
import { Col, Row } from 'reactstrap'
import logo from '../images/logotype.svg'
import { API_URL } from '../api/api'
import { useDispatch, useSelector } from 'react-redux'
import { fetchSocialMedias } from '../store/slices/socialMediaSlice'
import { fetchSettingById } from '../store/slices/settingSlice'
import { fetchShowCases } from '../store/slices/showCaseSlice';
import { fetchFooterMenus } from '../store/slices/footerMenuSlice';
import phone from "../images/phone.png";
import mail from '../images/mail.png'
import map from '../images/map.png'

const Footer = () => {
  const dispatch = useDispatch();
  const showCase = useSelector((state) => state.showCase.sc)
  const menu = useSelector((state) => state.footerMenu.fm)
  const sm = useSelector((state) => state.socialMedia.socialMedias)
  const sett = useSelector((state) => state.setting.settings)
  const [lang, setLang] = useState(localStorage.getItem("lang") || "EN")

  useEffect(() => {
    dispatch(fetchShowCases())
    dispatch(fetchFooterMenus())
    dispatch(fetchSocialMedias())
    dispatch(fetchSettingById())
  }, [dispatch]);

  return (
    <footer className="footer overflow-hidden">
      <Row>
        <Col md={2} className='first mb-5'>
          <div><img src={logo} alt='' className='logo' /></div>
          <div className='mb-3'></div>
          {
            showCase && showCase.length > 0 ? (
              showCase.map((item, index) => {
                let title = "";
                try {
                  const parsedTitle = lang === "TR" ? item.title : item.titleEN;
                  title = parsedTitle
                } catch (error) {
                  console.error("JSON parse error:", error);
                  title = "Error loading title";
                }

                return (
                  <div className='mb-2' key={index}>
                    <a target={index === 0 ? "_blank" : null} href={index === 0 ? "https://e-sirket.mkk.com.tr/?page=company&company=10819#" : `${lang === "TR" ? "/" : "/"}${lang === "TR" ? item.url : item.urlEN}`}>{title}</a>
                  </div>
                );
              })
            ) : null
          }
        </Col>
        <Col md={2} className='second mb-5'>
          <div className='title'>
            <h5>{lang === "TR" ? "Site Haritası" : "Sitemap"}</h5>
            <div></div>
          </div>

          <Row className='mt-2'>
            <Col md={12}>
              {
                menu && menu.length > 0 ? (
                  menu.map((item, index) => {
                    let title = "";
                    try {
                      const parsedTitle = lang === "TR" ? item.title : item.titleEN
                      title = parsedTitle
                    } catch (error) {
                      console.error("JSON parse error:", error);
                      title = "Error loading title";
                    }

                    return (
                      <div className='mb-3' key={index}>
                        {item.url !== "" ? <a href={item.url === "" ? "/" : lang === "TR" ? `/${lang === "TR" ? item.url : item.urlEN}` : `/${lang === "TR" ? item.url : item.urlEN}`}>{title}</a> : null}
                      </div>
                    );
                  })
                ) : null
              }
            </Col>
          </Row>
        </Col>
        <Col md={5} className='third mb-5'>
          <div className='title'>
            <h5>{lang === "TR" ? "İletişim" : "Contact"}</h5>
            <div id='contact-line'></div>
          </div>
          {
            sett ? (
              <div className='mt-4'>
                <div className='row'>
                  <div className='col-md-5'>
                    <h6 style={{ color: 'white' }}>İstanbul</h6>
                    <div className='mb-3 adres d-flex justify-content-start'>
                      <div><img src={map} alt='' /></div>
                      <div>
                        <a href={sett.location1}>
                          <span dangerouslySetInnerHTML={{
                            __html: lang === "TR"
                              ? sett.address1.replace(/\n/g, '<br/>')
                              : sett.address1EN.replace(/\n/g, '<br/>')
                          }} />
                        </a>
                      </div>
                    </div>
                    <div className='mb-3 telefon d-flex justify-content-start'>
                      <div><img src={phone} alt='' /></div>
                      <div>
                        <a href={sett.phone1 ? `tel:${sett.phone1}` : ""} target="_blank">
                          {sett.phone1 || ""}
                        </a>
                      </div>
                    </div>
                    <div className='mb-3 mail d-flex justify-content-start'>
                      <div><img src={mail} alt='' /></div>
                      <div>
                        <a href={sett.mail1 ? `mailto:${sett.mail1}` : ""} target="_blank">
                          {sett.mail1 || ""}
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className='col-md-6'>
                    <h6 style={{ color: 'white' }}>Ankara</h6>
                    <div className='mb-3 adres d-flex justify-content-start'>
                      <div><img src={map} alt='' /></div>
                      <div>
                        <a href={sett.location2}>
                          <span dangerouslySetInnerHTML={{
                            __html: lang === "TR"
                              ? sett.address2.replace(/\n/g, '<br/>')
                              : sett.address2EN.replace(/\n/g, '<br/>')
                          }} />
                        </a>
                      </div>
                    </div>
                    <div className='mb-3 telefon d-flex justify-content-start'>
                      <div><img src={phone} alt='' /></div>
                      <div>
                        <a href={sett.phone2 ? `tel:${sett.phone2}` : ""}>
                          {sett.phone2 || ""}
                        </a>
                      </div>
                    </div>
                    <div className='mb-3 mail d-flex justify-content-start'>
                      <div><img src={mail} alt='' /></div>
                      <div>
                        <a href={sett.mail2 ? `mailto:${sett.mail2}` : ""}>
                          {sett.mail1 || ""}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>


              </div>

            ) : null
          }
        </Col>
        <Col md={2} className='mb-5'>
          <div className='title'>
            <h5>{lang === "TR" ? "Sosyal Medya" : "Social Media"}</h5>
            <div></div>
          </div>

          <div className='social mt-4 d-flex justify-content-start'>
            {
              sm && sm.length > 0 ? (
                sm.map((item, index) => {
                  const imageUrl = item.fileFullPath ? `${API_URL}${item.fileFullPath}` : '';
                  return (
                    <div className='mr-3' key={index}>
                      <a target='_blank' href={lang === "TR" ? item.url : item.url || "#"}>
                        <img src={imageUrl} alt={item.altText || 'Image'} onError={(e) => e.target.src = '/default-image.jpg'} />
                      </a>
                    </div>
                  );
                })
              ) : null
            }
          </div>
        </Col>
      </Row>
    </footer >
  );
};

export default Footer;
