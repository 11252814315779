import React, { useEffect, useState } from 'react';
import logo from '../images/logotype.svg';
import { ChevronDown, Menu } from 'react-feather';
import { motion, AnimatePresence } from 'framer-motion';
import { headerVariant, mobilSubHeaderVariant } from './animation';
import { useDispatch, useSelector } from 'react-redux';
import { fetchHeaders } from '../store/slices/headerSlice';
import { fetchLanguages } from '../store/slices/languageSlice';

const Header = () => {
  const dispatch = useDispatch();
  const menus = useSelector((state) => state.header.menus);
  const langs = useSelector((state) => state.language.languages);
  const [openMenu, setOpenMenu] = useState(false);
  const [sParentMenu, setSParentMenu] = useState(null);
  const [openId, setOpenId] = useState(0);
  const [lang, setLang] = useState(localStorage.getItem("lang") || "EN");

  const handleLanguageChange = (languageCode) => {
    setLang(languageCode);
    localStorage.setItem("lang", languageCode);

    if (window.location.pathname !== "/") {
      menus.forEach(element => {
        if (element.isHeader || element.isShowCase) {

          if (element.urlEN === window.location.pathname.split("/")[1]) {
            window.location.assign(`/${element.url}`)
          }
          if (element.url === window.location.pathname.split("/")[1]) {
            window.location.assign(`/${element.urlEN}`)
          }
          element.subHeaders.forEach(element2 => {
            if (element2.urlEN === window.location.pathname.split("/")[1]) {
              window.location.assign(`/${element2.url}`)
            }
            if (element2.url === window.location.pathname.split("/")[1]) {
              window.location.assign(`/${element2.urlEN}`)
            }
          });
        }
      });
    } else {
      window.location.reload()
    }
  };

  useEffect(() => {
    dispatch(fetchHeaders());
    dispatch(fetchLanguages());
  }, [dispatch]);

  return (
    <header className="header shadow">
      <div>
        <a href='/'><img src={logo} alt='' /></a>
      </div>
      <div className='position-relative'>
        <motion.ul className='mt-2' {...headerVariant}>
          {
            menus && menus.length > 0 ? (
              menus
                .slice() // Create a shallow copy to avoid mutating the original array
                .sort((a, b) => a.sort - b.sort) // Sort by the 'sort' property
                .map((item, index) => {
                  return item.isHeader && item.url !== "" ? (
                    <li key={index} className="menu-item mr-5">
                      <a
                        href={item.url === "" ? "/" : lang === "TR" ? `${"/"}${lang === "TR" ? item.url : item.urlEN}` : `/${lang === "TR" ? item.url : item.urlEN}`}
                        onClick={() => localStorage.setItem("headerID", item.headerID)}
                      >
                        {(() => {
                          try {
                            const titleObj = lang === "TR" ? item.title : item.titleEN;
                            return titleObj;
                          } catch (error) {
                            console.error("Error parsing title:", error);
                            return "";
                          }
                        })()}
                      </a>
                      {item.subHeaders && item.subHeaders.length > 0 && (
                        <div className='d-flex justify-content-center'>
                          <div
                            className='sub-menu-toggle'
                            onClick={() => setSParentMenu(sParentMenu === item.headerID ? null : item.headerID)}
                          >
                            <ChevronDown />
                          </div>
                          <AnimatePresence>
                            <motion.div
                              className='sub-header shadow'
                              initial={{ opacity: 0, height: 0 }}
                              animate={{ opacity: 1, height: 'auto' }}
                              exit={{ opacity: 0, height: 0 }}
                              transition={{ duration: 0.3 }}
                            >
                              <ul className='pt-3'>
                                {item.subHeaders.map((item2, index2) => {
                                  return (
                                    <li key={index2}>
                                      <a href={lang === "TR" ? `${"/"}${lang === "TR" ? item2.url : item2.urlEN}` : `/${lang === "TR" ? item2.url : item2.urlEN}`} onClick={() => localStorage.setItem("headerID", item.headerID)}>
                                        {(() => {
                                          try {
                                            const titleObj = lang === "TR" ? item2.title : item2.titleEN;
                                            return titleObj;
                                          } catch (error) {
                                            console.error("Error parsing sub-header title:", error);
                                            return "";
                                          }
                                        })()}
                                      </a>
                                    </li>
                                  );
                                })}
                              </ul>
                            </motion.div>
                          </AnimatePresence>
                        </div>
                      )}
                    </li>
                  ) : null;
                })
            ) : null
          }
        </motion.ul>
      </div>
      <div className='d-flex justify-content-center text-white lang'>
        {
          langs.map((item, index) => (
            <div key={index} className='ml-2'>
              <span
                className='text'
                onClick={() => handleLanguageChange(item.languageCode)}
                role="button"
                tabIndex={0}
                onKeyPress={(e) => e.key === 'Enter' && handleLanguageChange(item.languageCode)}
              >
                {item.languageCode}
              </span>
              {index < langs.length - 1 && (
                <span className='ml-2 line'>|</span>
              )}
            </div>
          ))
        }
      </div>
      <AnimatePresence>
        {openMenu && (
          <motion.div
            className='mobile-menu'
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            transition={{ duration: 0.3 }}
          >
            <div className='mobile-menu2'>
              <div><img src={logo} alt='Logo' /></div>
              <div>
                {
                  menus.map((item, index) => (
                    item.isHeader ? (
                      <div key={index}>
                        <div className='mb-4 d-flex justify-content-between'>
                          <div>
                            <a
                              onClick={() => localStorage.setItem("headerID", item.headerID)}
                              href={lang === "TR" ? `/${lang === "TR" ? item.url : item.urlEN}` : `/${lang === "TR" ? item.url : item.urlEN}`}
                            >
                              {lang === "TR" ? item.title : item.titleEN}
                            </a>
                          </div>
                          {item.subHeaders.length > 0 && (
                            <div>
                              <ChevronDown onClick={() => setOpenId(openId === item.headerID ? null : item.headerID)} />
                            </div>
                          )}
                        </div>
                        {openId === item.headerID && (
                          <motion.div
                            {...mobilSubHeaderVariant}
                            className='mb-4 border-bottom border-top mx-0 px-0 pt-2'
                          >
                            {
                              item.subHeaders.map((item2, index2) => (
                                <div key={index2} className='mb-3'>
                                  <a href={lang === "TR" ? `/${lang === "TR" ? item2.url : item2.urlEN}` : `/${lang === "TR" ? item2.url : item2.urlEN}`} onClick={() => setOpenMenu(false)}>
                                    {lang === "TR" ? item2.title : item2.titleEN}
                                  </a>
                                </div>
                              ))
                            }
                          </motion.div>
                        )}
                      </div>
                    ) : null
                  ))
                }
              </div>
              <div className='d-flex justify-content-center text-white lang'>
                {langs.map((item, index) => (
                  <div key={index} className='ml-2'>
                    <span
                      className='text'
                      onClick={() => handleLanguageChange(item.languageCode)}
                      role="button"
                      tabIndex={0}
                      onKeyPress={(e) => e.key === 'Enter' && handleLanguageChange(item.languageCode)}
                    >
                      {item.languageCode}
                    </span>
                    {index < langs.length - 1 && (
                      <span className='ml-2 line'>|</span>
                    )}
                  </div>
                ))}
              </div>
            </div>
            <div className='space' onClick={() => setOpenMenu(false)}>
              <div>x</div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
      <div className='justify-content-end menubtn' onClick={() => setOpenMenu(true)}>
        <Menu className='text-white border border-white rounded' size={35} />
      </div>
    </header >
  );
};

export default Header;
