import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchJob } from '../store/slices/jobSlice'
import { fetchJobs } from '../store/slices/jobsSlice'
import { Table } from 'reactstrap';

const Job = () => {
    const dispatch = useDispatch();
    const jobsResponse = useSelector((state) => state.jobs.jobs);
    const response = useSelector((state) => state.job.job);
    const [lang, setLang] = useState(localStorage.getItem("lang") || "EN");
    const [load, setLoad] = useState(false);
    const [data, setData] = useState({});
    const [jobs, setJobs] = useState([]);
    const [displayText, setDisplayText] = useState('');

    const getData = async () => {
        try {
            if (response) {
                setData(response);
                const parsedData = lang === "TR" ? response.imageText : response.imageTextEN;
                setDisplayText(parsedData);
            } else {
                setDisplayText('No data available');
            }

            setJobs(jobsResponse);
            if (data !== undefined && data !== null) {
                setTimeout(() => {
                    setLoad(true);
                }, 300);
            }
        } catch (error) {
            console.error('API call error:', error);
            setDisplayText('Error fetching data');
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            await dispatch(fetchJob());
            await dispatch(fetchJobs());
        };
        fetchData();
    }, [lang]);

    useEffect(() => {
        getData();
    }, [response, jobsResponse, lang]);

    return (
        <div className='job-page' id='job-component'>
            <div className='jobs'>
                <div><h2>{lang === "TR" ? data.title : data.titleEN}</h2></div>
                <Table>
                    <thead>
                        <tr>
                            <th style={{borderBottomLeftRadius: "8px", borderTopLeftRadius: "8px"}}>{lang === "TR" ? "Pozisyon" : "Position"}</th>
                            <th>{lang === "TR" ? "Kıdem" : "Seniority"}</th>
                            <th>{lang === "TR" ? "Lokasyon" : "Location"}</th>
                            <th style={{borderBottomRightRadius: "8px", borderTopRightRadius: "8px"}}></th>
                        </tr>
                    </thead>
                    <tbody>
                        {jobs.length > 0 ? (
                            jobs.map((item, index) => (
                                <tr>
                                    <td>{lang === "TR" ? item.position : item.positionEN}</td>
                                    <td>{lang === "TR" ? item.seniority : item.seniorityEN}</td>
                                    <td>{lang === "TR" ? item.location : item.locationEN}</td>
                                    <td>{item.url === "close" ? (
                                        <p>{lang === "TR" ? "Kapalı" : "Closed"}</p>
                                    ) : (
                                        <a href={item.url} target="_blank" rel="noopener noreferrer">
                                        </a>
                                    )}</td>
                                </tr>

                            ))
                        ) : (
                            <div className="row job">
                                <div className="col-md-12">{lang === "TR" ? "İş ilanı bulunamadı" : "No jobs available"}</div>
                            </div>
                        )}
                    </tbody>
                </Table>
                <div id="jobs">
                    <div className="row title">
                        <div className="col-md-4">
                            {lang === "TR" ? "Pozisyon" : "Position"}
                        </div>
                        <div className="col-md-4">
                            {lang === "TR" ? "Kıdem" : "Seniority"}
                        </div>
                        <div className="col-md-3">
                            {lang === "TR" ? "Lokasyon" : "Location"}
                        </div>
                        <div className="col-md-1"></div>
                    </div>
                    {jobs.length > 0 ? (
                        jobs.map((item, index) => (
                            <div key={index} className="row job">
                                <div className="col-md-4">{lang === "TR" ? item.position : item.positionEN}</div>
                                <div className="col-md-4">{lang === "TR" ? item.seniority : item.seniorityEN}</div>
                                <div className="col-md-3">{lang === "TR" ? item.location : item.locationEN}</div>
                                <div className="col-md-1">
                                    {item.url === "close" ? (
                                        <p>{lang === "TR" ? "Kapalı" : "Closed"}</p>
                                    ) : (
                                        <a href={item.url} target="_blank" rel="noopener noreferrer">
                                        </a>
                                    )}
                                </div>
                            </div>
                        ))
                    ) : (
                        <div className="row job">
                            <div className="col-md-12">{lang === "TR" ? "İş ilanı bulunamadı" : "No jobs available"}</div>
                        </div>
                    )}
                </div>
                <div className='app-btn' onClick={() => {
                    if (lang === "EN") { window.location.href = '/join-mapa' }
                    else { window.location.href = '/mapaya-katilin' }
                }}>{lang === "TR" ? "Katıl" : "Apply"}</div>
            </div>
        </div>
    )
}

export default Job
