import React from 'react';
import Header from '../../components/header';
import Footer from '../../components/footer';
import { Info } from 'react-feather';

const NoPage = () => {

  return (
    <div className='contentpage'>
      <Header />
      <div className='d-flex flex-column justify-content-center align-items-center' style={{ height: '100vh' }}>
        <Info className='text-danger' size={80} style={{marginBottom: 20}} />
        <h1>404 - Sayfa Bulunamadı</h1>
        <p>Üzgünüz, aradığınız sayfa mevcut değil.</p>
      </div> 
      <Footer />
    </div>
  );
};

export default NoPage;
